/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

html, body {
  box-sizing: border-box;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #d1c4e9;
  overflow-x: hidden;
}
